import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { Box } from 'components/v2/Box';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeMainColor,
  getThemeNeutralColor,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';

const ArrowContainer = styled.span<{
  isActive: boolean;
  itemsLength: number;
}>(({ isActive, itemsLength }) => ({
  position: CSS_POSITION.ABSOLUTE,
  display: itemsLength > 3 ? CSS_DISPLAY.BLOCK : CSS_DISPLAY.NONE,
  top: '110px',
  cursor: isActive ? 'pointer' : 'default',
  svg: {
    opacity: isActive ? 1 : 0.3,
  },
  [MEDIA_QUERIES.TABLET]: {
    top: '90px',
    display: itemsLength > 2 ? CSS_DISPLAY.BLOCK : CSS_DISPLAY.NONE,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    display: CSS_DISPLAY.NONE,
  },
}));

export const LeftArrowContainerDesktop = styled(ArrowContainer)({
  left: 0,
});

export const RightArrowContainerDesktop = styled(ArrowContainer)({
  right: 0,
});

export const ItemsContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  margin: '0 auto',
  gap: '24px',
  justifyContent: 'center',
  [MEDIA_QUERIES.TABLET]: {
    margin: '0 auto',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '36px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gap: '20px',
  },
});

export const Card = styled.div<{ isClickable: boolean }>(({ isClickable }) => ({
  ...flexColContainerStyles,
  background: themev2.colors.white.default,
  alignItems: CSS_JUSTIFY_CONTENT.START,
  boxShadow: '0px 4px 16px 0px rgba(29, 29, 31, 0.08)',
  padding: '36px 24px',
  borderRadius: '12px',
  border: `1px solid ${themev2.colors.white.default}`,
  width: '262px',
  textAlign: TEXT_ALIGN.LEFT,
  p: { margin: 0 },
  a: {
    color: themev2.colors.neutral.N99,
  },
  ':hover': {
    border: isClickable
      ? `1px solid ${themev2.colors.primary.P99}`
      : `1px solid ${themev2.colors.white.default}`,
  },
  [MEDIA_QUERIES.TABLET]: {
    width: 'calc(50% - 12px)',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    minWidth: '100%',
    width: '100%',
    padding: '32px 20px',
  },
}));

export const StyledTitle = styled.div({
  margin: '16px 0 24px',
  'p, em, strong': {
    fontSize: '20px',
    lineHeight: 'normal',
  },
  strong: {
    fontWeight: 700,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: '20px',
  },
});

export const StyledDescription = styled.div({
  'p, strong, em': {
    fontSize: '16px',
  },
});

export const StyledUserName = styled.div((props) => ({
  'p, strong, em': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
    marginBottom: 0,
  },
}));

export const BottomTextContainer = styled.div((props) => ({
  margin: '0 auto',
  marginTop: '36px',
  display: CSS_DISPLAY.FLEX,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  img: {
    width: 'auto',
    height: '24px',
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: 'start',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    alignItems: CSS_ALIGN.CENTER,
    img: {
      marginBottom: getThemeGutter(props),
    },
  },
}));

export const BottomText = styled.div((props) => ({
  'p, strong, em, a': {
    display: CSS_DISPLAY.INLINE,
    fontSize: '16px',
    a: {
      fontSize: '16px',
      color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
      fontFamily: themev2.fonts.family.additional,
    },
  },
}));

export const AwardsImage = styled.div({
  height: 'auto',
  width: '60px',
  div: {
    height: 'auto',
    width: '60px',
  },
});

export const TabsContainer = styled.div((props) => ({
  maxWidth: '440px',
  display: CSS_DISPLAY.GRID,
  alignItems: CSS_ALIGN.CENTER,
  textAlign: TEXT_ALIGN.CENTER,
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: getThemeGutter(props, TSHIRT_SIZE.S),
  'p, em, strong': {
    margin: 0,
    padding: `${getThemeGutter(props, TSHIRT_SIZE.XS)} 0`,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: 500,
    cursor: 'pointer',
    [DOM_STATE.HOVER]: {
      color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    display: CSS_DISPLAY.NONE,
  },
}));

export const MobileTabsContainer = styled.div((props) => ({
  display: CSS_DISPLAY.NONE,
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: '0 auto',
    boxShadow: '0 0 16px 0 rgba(0,0,0,.1)',
    width: 'calc(100% - 60px)',
    borderRadius: '8px',
    height: '42px',
    display: CSS_DISPLAY.FLEX,
    alignItems: CSS_ALIGN.CENTER,
    textAlign: TEXT_ALIGN.CENTER,
    'p, em, strong': {
      marginBottom: 0,
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      fontWeight: 500,
    },
  },
}));

export const ImageContainer = styled.div({
  width: '128px',
  height: '24px',
  div: {
    width: '128px',
    height: '24px',
  },
});

export const SocialProofToggleButtonContainer = styled.div({
  position: 'relative',
  width: 'fit-content',
  borderRadius: '24px',
  padding: '2px',
  border: `1px solid ${themev2.colors.neutral.N10}`,
  display: 'flex',
  backgroundColor: themev2.colors.neutral.N00,
  ':hover': {
    transition: 'all 0.1s ease-in-out 0s',
    background: themev2.colors.neutral.N04,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: 'column',
    border: 'none',
    gap: '24px',
    margin: '0 auto',
    width: '100%',
    ':hover': {
      transition: 'none',
      background: themev2.colors.white.default,
    },
  },
});

export const SocialProofTab = styled.div<{ isActive: boolean }>(
  ({ isActive }) => ({
    padding: '12px 24px',
    borderRadius: '24px',
    cursor: 'pointer',
    textAlign: 'center',
    color: isActive ? themev2.colors.neutral.N00 : themev2.colors.neutral.N99,
    minWidth: '100px',
    fontFamily: themev2.fonts.family.main,
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: 1,
    fontWeight: 400,
    zIndex: 2,
    transition: '0.3s',
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      minWidth: '60px',
      padding: '12px 12px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      border: `1px solid ${themev2.colors.neutral.N10}`,
      width: '100%',
    },
  })
);

export const SocialProofSelectedTabBackground = styled(Box)<{
  tabsNumber: number;
}>(({ tabsNumber }) => ({
  position: 'absolute',
  top: '2px',
  padding: '12px 24px',
  borderRadius: '24px',
  height: 'calc(100% - 4px)',
  backgroundColor: themev2.colors.neutral.N99,
  zIndex: 1,
  transition: '0.3s',
  minWidth: '100px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    minWidth: '60px',
    padding: '12px 12px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    top: '4px',
    left: '4px',
    height: `calc(100% / ${tabsNumber} - 22px)`,
    width: 'calc(100% - 20px)',
    transition: 'none',
    minWidth: 'unset',
  },
}));
